export const environment = {
  production: true,
  inactive: false,

  BASE_LA: 'https://dev-api.creditotal.online',
  BASE_FORNT: 'https://dev-console.creditotal.online',
  BASE_FORNT_CLIENT: 'https://dev-app.creditotal.online',

  redirectRoute:"/home",
  searchClient:"/console/financial-payments",
  psicometrico:"/console/psicometrico",
  initRoute:"/home",

  externalPsicometrico:'https://www.acredita.com.ve:8860/psicometrico/home_test.php',
  
  loginRoute:"/login",
  logoutRoute:"/logout",

  socketBase:{
    SOCKET_ENDPOINT: 'https://valores-rvapi.akeela.co/'
  },
  
  ClieID:"12",
  CED:"11314836",
  IP_LOCAL:"34.225.156.128"

};
