import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/auth/login-form/login.component';
import { LogoutComponent } from './components/auth/logout/logout.component';
import { IsAuthGuard } from './guards/isAuth.guard';
import { NoAuthGuard } from './guards/NoAuth.guard';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PostComponent } from './components/post/post.component';
import { CreditCalculatorComponent } from './components/credit-calculator/credit-calculator.component';
import { ActiveCupoComponent } from './components/active-cupo/active-cupo.component';
import { ApproveConditinsViewComponent } from './components/approve-conditins-view/approve-conditins-view.component';
import { ApproveConditionsComponent } from './components/approve-conditions/approve-conditions.component';
import { InvoiceDetailComponent } from './components/invoice-detail/invoice-detail.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { TycComponent } from './components/tyc/tyc.component';
import { DeleteAccountComponent } from './components/delete-account/delete-account.component';

const routes: Routes = [

  /*{
    path: 'console',
    loadChildren: () => import('./modules/la/la.module').then(m => m.LaModule),
    canActivate: [AuthGuard]

  },*/
  {
    path: 'active',
    component: ActiveCupoComponent,
    canActivate: [NoAuthGuard]

  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NoAuthGuard]

  },
  {
    path: 'logout',
    component: LogoutComponent,

  },
  { path: 'home',
  component: DashboardComponent,
  canActivate: [IsAuthGuard]

  },

  {
    path: 'financial',
    component: InvoicesComponent,
    canActivate: [IsAuthGuard]
  },
  
  { path: 'payment/:invoice/:installment',
    component: PostComponent,
    canActivate: [IsAuthGuard]
  },
  
  { path: 'calculator',
    component: CreditCalculatorComponent,
    canActivate: [IsAuthGuard]
  },
  

  { path: 'conditions',
     component: ApproveConditinsViewComponent,
     canActivate: [NoAuthGuard]

  },

  { path: 'tyc',
    component: TycComponent,
    canActivate: [NoAuthGuard]

 },

 { path: 'delete-account',
  component: DeleteAccountComponent,
  canActivate: [NoAuthGuard]

},
    { path: 'verification/approve-conditions',
    component: ApproveConditionsComponent
  },        
    { path: 'invoice-detail/:code',
        component: InvoiceDetailComponent
      },

      { path: 'payments',
      component: PaymentsComponent
    },

  {
    path: '**',
    redirectTo: 'login'
  },



  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
